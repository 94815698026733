import React from 'react'
import { navigate } from 'gatsby'
import Layout from '../components/layout'
import MainWrap from '../components/mainwrap'
import H1 from '../components/h1'
import BlockContent from '../components/blockcontent'
import Button from '../components/button'
import { TermsStyle } from '../styles/common'
import { Margin } from 'styled-components-spacing'
import useCampaignQuery from '../graphql/queries'

export default function TermsPage() {
          
    const { sanityCampaign } = useCampaignQuery()

    return (
  <Layout>
    <MainWrap>
    <H1 title="Konkurransebetingelser"></H1>
    {sanityCampaign._rawTerms !== null &&
        <TermsStyle><BlockContent blocks={sanityCampaign._rawTerms} /></TermsStyle>
    }
    <Margin top={5}>
    <Button title="Tilbake til forsiden" handleClick={ () => { navigate("/") }} answers={null} outline={true} small={true}></Button>
    </Margin>
        
    </MainWrap>
    </Layout>
)}